.main-case-studies-hero {
  background: $secondary_color;

  .case-studies-banner {
    @include flexBox(column, center, center);

    position: relative;
    min-height: 31.9rem;
    overflow: hidden;

    &.main-container {
      margin-top: 7.7rem;

      @media (max-width: $breakpoint-mobile) {
        margin-top: 7.1rem;
      }
    }

    .background {
      margin: 0;

      .circle {
        position: absolute;
        background: rgba($onahau_color, 0.07);
        border-radius: 50%;

        &.circle1 {
          height: 13.2rem;
          width: 13.2rem;
          top: 6.2rem;
          // left: -3.4rem; uncomment if necessary
          left: 0;
        }

        &.circle2 {
          height: 5.4rem;
          width: 5.4rem;
          left: 54.4rem;
          bottom: 3rem;
        }

        &.circle3 {
          height: 3.7rem;
          width: 3.7rem;
          top: 5.5rem;
          right: 33.1rem;
        }

        &.circle4 {
          height: 1.4rem;
          width: 1.4rem;
          right: 6.6rem;
          bottom: 15rem;
        }
      }
    }
  }
}

.contact-section {
  // @include grid(repeat(2, 1fr));
  @include grid(1fr);

  justify-content: space-between;
  align-items: stretch;
  min-height: 57.3rem;
  background: $dark_blue_color;
  padding-top: 4rem;

  .form-wrapper {
    padding: 5rem 6.6rem 5.2rem 7.4rem;

    &.main-container {
      // padding: 0;
      max-width: 95rem;
    }
  }

  @media (max-width: $breakpoint-mid-mobile) {
    @include grid(1fr);

    .form-wrapper {
      order: 1;
      padding: 5rem 1rem 7.4rem;
    }
  }

  .map-container,
  .form-wrapper {
    min-height: 57.3rem;
  }
}

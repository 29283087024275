.main-about-hero {
  position: relative;
  //   padding: 5.7rem 0 9.74rem;
  overflow: hidden;

  &::after {
    position: absolute;
    content: "";
    inset: 0;
    background-image: linear-gradient(
      131deg,
      rgba(255, 255, 255, 0) 15%,
      rgba(56, 165, 254, 0.16) 89%
    );
    z-index: -2;
  }

  .about-us-section {
    @include flexBox(column, center, center);

    position: relative;
    // min-height: 41.6rem;
    padding-top: 17.7rem;
    padding-bottom: 9.6rem;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: url(https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/hero-bg-pattern.webp);
      background-size: cover;
      background-position: 40rem 4.7rem;
      -webkit-transform: rotateX(10deg);
      transform: rotateX(30deg) scale(1.9) rotateY(9deg);
      background-repeat: no-repeat;
      z-index: -1;

      @media (max-width: $breakpoint-desktop) {
        background-position: 23rem 2.5rem;
      }
    }
  }
}

.main-stats-section {
  background: $primary_color;

  .stats-container {
    @include grid(repeat(3, auto));

    justify-content: center;
    align-items: center;
    gap: 10.5rem;
    padding-bottom: 1.9rem;

    @media (max-width: $breakpoint-tablet) {
      gap: 5rem;
    }

    @media (max-width: $breakpoint-mobile-max) {
      gap: 3rem;
      padding: 2rem;
    }

    @media (max-width: $breakpoint-mobile) {
      @include grid(1fr);
    }

    .stats-wrapper:not(:first-child) {
      @include grid(repeat(2, auto));

      justify-content: center;
      align-items: center;
      gap: 10.5rem;

      @media (max-width: $breakpoint-tablet) {
        gap: 5rem;
      }

      @media (max-width: $breakpoint-mobile-max) {
        gap: 3rem;
      }

      @media (max-width: $breakpoint-mobile) {
        @include grid(1fr);
      }

      &::before {
        content: "";
        width: 0.2rem;
        height: 10.2rem;
        opacity: 0.5;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #ffffff 50%,
          rgba(255, 255, 255, 0)
        );

        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }
    }

    .stats-wrapper {
      color: $white_color;

      .stats {
        @include grid(max-content);

        justify-content: center;
        align-items: center;
        text-align: center;
        height: 12.4rem;

        .count {
          min-width: 16rem;

          & > span {
            font-size: $text_size_8;
            font-family: "Oswald", sans-serif;
            line-height: 1.48;
            margin: 0;
            color: $white_color;

            @media (max-width: $breakpoint-mobile) {
              font-size: $text_size_7;
            }
          }
        }

        .title {
          font-size: $text_size_5;
          font-family: "Open Sans", sans-serif;
          line-height: 1.38;
          margin: 0;
        }
      }
    }
  }
}

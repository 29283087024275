@mixin grid($grid-columns, $column-gap: 0, $row-gap: 0, $grid-rows: none) {
  display: grid;
  grid-template-columns: $grid-columns;
  grid-column-gap: $column-gap;
  grid-row-gap: $row-gap;
  grid-template-rows: $grid-rows;
}

@mixin flexBox(
  $flex-direction: row,
  $align-items: flex-start,
  $justify-content: flex-start
) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin boxShadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin gap($gap) {
  > *:not(:last-child) {
    margin-right: $gap;
  }
}

@mixin max-width-ellipsis($max-width) {
  width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-text-with-image {
  background-color: $alice_blue_color;

  .text-with-image-section {
    @include grid(41.86rem 61.2rem);

    padding: 5.61rem 7.1rem 5.51rem 10.91rem;

    justify-content: space-between;
    // align-items: start;
    align-items: center;

    @media (max-width: $breakpoint-desktop) {
      @include grid(repeat(2, 1fr));

      gap: 2rem;
      align-items: center;
      padding: 5.61rem 1rem 10.91rem;
    }

    @media (max-width: $breakpoint-mid-mobile) {
      @include grid(1fr);

      .image {
        order: 1;
        object-fit: contain;
      }
    }

    .image {
      width: 100%;
      height: 33.6rem;
    }

    &.happy-users {
      @include grid(56.6rem 39.4rem);

      padding-bottom: 0;
      align-items: center;

      @media (max-width: $breakpoint-desktop) {
        @include grid(repeat(2, 1fr));
      }

      @media (max-width: $breakpoint-mid-mobile) {
        @include grid(1fr);

        .image {
          order: 1;
          object-fit: contain;
        }
      }

      .image {
        width: 100%;
        height: 33.5rem;
      }
    }

    .text-section {
      @include flexBox(column, baseline, flex-start);

      .heading {
        @extend .main-heading;
      }

      .count {
        font-size: $text_size_9;
        color: $primary_color;
        margin: 0 0 1.5rem 0;
        line-height: 0.88;
        font-family: "Oswald", sans-serif;
        font-weight: normal;

        @media (max-width: $breakpoint-mobile) {
          font-size: $text_size_8;
        }
      }

      .text {
        margin-top: 0.3rem;
        font-size: $text_size_3;
        color: rgba($secondary_color, 0.77);
      }
    }
  }
}

.form-heading {
  color: $white_color;
  font-size: $text_size_6;
  font-family: 'Poppins', sans-serif;
  margin: 0;

  @media (max-width: $breakpoint-mid-mobile) {
    font-size: $text_size_5_1;
  }
}

.contact-form {
  @include grid(1fr);

  gap: 1.2rem;
  margin-top: 2.8rem;

  .custom-select-1 {
    background-color: transparent;
    height: 100%;
    width: 100%;
    border-radius: 0.7em;
    padding: 0 1.8rem;
    color: $white_color;
    padding-top: 1.2rem;
    border-color: $fiord_color;
    &:focus {
      outline: none;
      border-color: $primary_color;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($shadow_blue_color, 0.44);
      line-height: 1.38;
    }
  }
  .message {
    color: #fff;
  }

  .input-wrapper {
    height: 7.8rem;
    position: relative;
    border-radius: 0.7rem;

    .text-input,
    .custom-select {
      @extend .custom-select-1;
      border-color: $fiord_color;
    }
    .error {
      @extend .custom-select-1;
      border-color: $red_color;
    }

    .ant-select {
      &.custom-select {
        padding: 0;

        &.ant-select-open,
        &.ant-select-focused {
          .ant-select-selector {
            box-shadow: none;
            border-color: $primary_color;

            &:focus {
              outline: none;
              border-color: $primary_color;
              box-shadow: none;
            }
          }
        }

        .ant-select-selector {
          background: transparent;
          border-radius: 0.7rem;
          width: 100%;
          height: 100%;
          border-color: $fiord_color;
          padding-top: 3rem;

          .ant-select-selection-placeholder {
            margin-left: 0.6rem;
            color: rgba($shadow_blue_color, 0.44);
          }

          .ant-select-selection-item {
            margin-left: 0.6rem;
          }
        }
      }
    }

    .--label {
      position: absolute;
      top: 0.8rem;
      left: 1.8rem;
      font-size: 1.4rem;
      font-family: 'Open Sans', sans-serif;
      color: $shadow_blue_color;
    }

    .--polygon {
      position: absolute;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #6d8ca5;
      bottom: 2.7rem;
      right: 1.7rem;
    }
  }

  .custom-button {
    width: 15.688rem;
    margin-top: 1.2rem;
  }

  .custom-button[disabled] {
    background: #d9d9d9;
  }
}

$text_size_1: 1.2rem;
$text_size_2: 1.3rem;
$text_size_3: 1.6rem;
$text_size_4: 1.8rem;
$text_size_5: 2.1rem;
$text_size_5_1: 2.4rem;
$text_size_6: 2.8rem;
$text_size_7: 5rem;
$text_size_8: 6rem;
$text_size_9: 7.6rem;

$primary_color: #38a5fe;
$primary_color_light: #35a4ff;
$secondary_color: #13314a;
$mirage_color: #14212c;
$alice_blue_color: #f6fbff;
$mercury_color: #e2e2e2;
$link_water_color: #d2e6f6;
$light_blue_color: #ecf4fb;
$alto_color: #d1d1d1;
$onahau_color: #cde9ff;
$dark_blue_color: #233340;
$white_color: #fff;
$black_color: #000;
$black_haze_color: #f1f2f2;
$red_color: #f95454;
$text_color_light: #0d1114;
$fiord_color: #3e5568;
$shadow_blue_color: #788c9d;
$cyan_blue_color: #deeaf3;

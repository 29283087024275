.testimonial-section-connect {
  .verticle-testimonial {
    right: -6rem;
    top: 2rem !important;
    left: auto !important;
    width: 26.6rem !important;
    height: 45.7rem !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .carousel-card-container {
    .carousel-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}

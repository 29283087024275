.testimonial-section {
  position: relative;
  min-height: 79.2rem;
  padding: 5.7rem 0 9.74rem;
  overflow: hidden;

  @media (max-width: $breakpoint-large-laptops) {
    &.main-container {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    min-height: auto;
    padding: 5.7rem 0 2rem;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 5.7rem 0 0;
  }

  &::before {
    content: '';
    background: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/testimonials-section-bg.webp');
    background-size: contain;
    background-position: 0 -3.2rem;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    transform: scale(1.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media (max-width: $breakpoint-large-laptops) {
      background-position: 0 2.8rem;
    }

    @media (max-width: $breakpoint-mobile-max) {
      background-position: 5rem 8rem;
    }
  }

  .description {
    color: rgba($secondary_color, 0.77);
    font-size: $text_size_3;
    margin: 0.5rem 0;
    line-height: 2.2rem;
  }

  .author {
    color: $primary_color;
    font-size: $text_size_2;
    margin: 1rem 0 0.5rem;
  }

  .designation {
    color: rgba($secondary_color, 0.72);
    font-size: 1.3rem;
    margin: 0;
  }

  .company {
    color: rgba($secondary_color, 0.72);
    font-size: 1.3rem;
    margin: 0;
  }

  .testimonial-image-container {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: solid 0.1rem $alto_color;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }

  .monitor {
    position: relative;
    height: 55.66rem;
    width: 83.2rem;
    background: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/testimonial-monitor.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 3.5rem;
    margin: 4.7rem auto 0 27rem;

    @media (max-width: $breakpoint-large-laptops) {
      height: 50.66rem;
      width: 79.2rem;
      margin: 4.7rem auto 0 17rem;
    }

    @media (max-width: $breakpoint-mobile-max) {
      display: none;
    }

    .clients {
      height: 41.2rem;
      width: 79rem;
      background: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/testimonial-clients.webp');
      background-size: contain;
      margin: auto;
      background-repeat: no-repeat;

      .client {
        opacity: 0.1;
      }

      @media (max-width: $breakpoint-large-laptops) {
        height: 37.2rem;
        width: 74rem;
      }

      .client {
        position: absolute;
      }

      .client:nth-child(1) {
        right: 5rem;
        bottom: 20rem;

        @media (min-width: $breakpoint-large-laptops) {
          right: 2rem;
          bottom: 21.7rem;
        }
      }
    }

    .card {
      background-color: $white_color;
      padding: 1.2rem 2rem 1.45rem 1.65rem;
      border: solid 0.1rem $mercury_color;
      border-radius: 0.8rem;
      position: absolute;

      &.--top-left {
        top: 1.6em;
        left: 6.6rem;
        max-width: 39.6rem;
        min-height: 18.2rem;

        @media (max-width: $breakpoint-large-laptops) {
          top: 0.5em;
          left: 7rem;
        }
      }

      &.--bottom-right {
        bottom: 9.86rem;
        right: 6.5rem;
        max-width: 46.6rem;
        min-height: 18.6rem;

        @media (max-width: $breakpoint-large-laptops) {
          right: 9.5rem;
          bottom: 1.1rem;
          max-width: 40.6rem;
        }
      }
    }

    .verticle-testimonial {
      position: absolute;
      top: 12.5rem;
      left: -16rem;
      padding: 2.3rem 1.25rem 0 1.55rem;
      background: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/testimonial-vertical.webp');
      background-size: contain;
      height: 33.1rem;
      width: 19.5rem;

      .content {
        .description {
          @extend .description;
        }

        .author {
          @extend .author;
        }

        .designation {
          @extend .designation;
        }

        .company {
          @extend .company;
        }
      }
    }

    .testimonial-image-container {
      @extend .testimonial-image-container;
    }

    .extra-design-element {
      position: absolute;
      bottom: -4rem;
      right: 6.3rem;
      font-family: EngraversMT;
      font-size: 11.6rem;
      line-height: 0.18;
      color: $link_water_color;
    }
  }

  .testimonial-carousel {
    display: none;

    @media (max-width: $breakpoint-mobile-max) {
      display: flex;
    }

    .carousel-card-container {
      width: 93%;
      margin: 5rem auto;
      padding: 4rem 2rem;
      border-radius: 0.8rem;
      box-shadow: 0 0.3rem 1.4rem 0 $mercury_color;
      background-color: $white-color;
      height: 54rem;

      @media (max-width: $breakpoint-mobile) {
        height: 55rem;
      }
      @media (max-width: 600px) {
        height: 65rem;
      }
      @media (max-width: 550px) {
        height: 67rem;
      }
      @media (max-width: 500px) {
        height: 45rem;
      }
      @media (max-width: $breakpoint-mobile-medium) {
        height: 55rem;
      }
      @media (max-width: 350px) {
        height: 60rem;
      }
      @media (max-width: 315px) {
        height: 65rem;
      }

      @media (min-width: $breakpoint-tablet) {
        padding: 6rem 4rem;
        width: 90%;
        height: 36.7rem;
      }

      .carousel-card {
        @include flexBox(column, flex-start, space-around);

        .description {
          @extend .description;
        }

        .author {
          color: $primary_color;
          font-size: $text_size_2;
          margin: 1rem 0 0.5rem;
        }

        .designation {
          color: rgba($secondary_color, 0.72);
          font-size: 1.3rem;
          margin: 0;
        }

        .company {
          @extend .company;
        }

        .testimonial-image-container {
          @extend .testimonial-image-container;

          width: 5rem;
          height: 5rem;
        }

        .extra-design-element {
          display: none;
        }
      }
    }
  }
}

.react-multi-carousel-list {
  .react-multi-carousel-track {
    .react-multi-carousel-item {
      opacity: 0.7;

      transform: scale(0.9);

      &.react-multi-carousel-item--active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        z-index: 1;
      }
    }
  }
  .react-multi-carousel-dot-list {
    margin: 20px auto 20px auto;
  }

  .react-multi-carousel-dot button {
    height: 15px;
    width: 15px;
    display: block;
    border-radius: 50%;
    border: none;
    background-color: #ccc;
  }
  .react-multi-carousel-dot--active button {
    background-color: #34a4ff;
  }
  .react-multiple-carousel__arrow {
    height: 100vh;
    width: 17vw;
    opacity: 0;
    z-index: 0;

    &.react-multiple-carousel__arrow--left {
      left: 0;
    }

    &.react-multiple-carousel__arrow--right {
      right: 0;
    }
  }
}

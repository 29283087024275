@import "styles/mixins.scss";

.default-card {
  max-width: 58.6rem;
  background: $white_color;
  border-radius: 0.8rem;

  @media (max-width: $breakpoint-tablet-small) {
    margin: 0 auto;
  }

  &.case-study {
    max-width: 59.1rem;
    justify-self: center;
  }

  &.--link {
    cursor: pointer;
  }

  &.--shadow {
    box-shadow: 0 0 15px 0 rgba($black_color, 0.16);
  }

  .image-container {
    .card-image {
      width: 100%;
      max-height: 33.5rem;
      // object-fit: contain;

      img {
        width: 100%;
      }
    }

    .case-studies {
      width: 100%;
      height: 33.5rem;
      // object-fit: contain;

      @media (max-width: 1255px) {
        height: 30.5rem;
      }
      @media (max-width: 1195px) {
        height: 28.5rem;
      }
      @media (max-width: 1120px) {
        height: 26.5rem;
      }
      @media (max-width: 1020px) {
        height: 23.5rem;
      }
      @media (max-width: 920px) {
        height: 20.5rem;
      }
      @media (max-width: 810px) {
        height: 17.5rem;
      }
      @media (max-width: 800px) {
        height: 30rem;
      }
      @media (max-width: 720px) {
        height: 31rem;
      }
      @media (max-width: 680px) {
        height: 30.5rem;
      }
      @media (max-width: 560px) {
        height: 28.5rem;
      }
      @media (max-width: 465px) {
        height: 25rem;
      }
      @media (max-width: 420px) {
        height: 22.5rem;
      }
      @media (max-width: 375px) {
        height: 20.5rem;
      }
      @media (max-width: 340px) {
        height: 17.5rem;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 0.8rem 0.8rem 0 0;
        height: inherit;
        object-fit: cover;
      }
    }

    .card-video {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem 0.8rem 0 0;
      object-fit: initial;
    }

    .card-video:hover {
      cursor: pointer;
      &.no-link {
        cursor: default
      }
    }
  }

  .card-text {
    padding: 0 1.9rem 0.5rem;

    .title {
      font-size: $text_size_5;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      line-height: 1.48;
      margin: 0;
    }

    .description {
      font-size: $text_size_3;
      font-family: "Open Sans", sans-serif;
      color: $secondary_color;
      line-height: 1.38;
      margin-top: 0.3rem;
    }
  }

  &.case-studies {
    max-width: 39.1rem;
    background: transparent;

    .card-text {
      padding: 0 0 0.5rem;

      .description {
        color: rgba($secondary_color, 0.77);
        letter-spacing: -0.013rem;
        margin: 0.3rem 0;
      }
    }

    .link {
      font-size: $text_size_3;
      font-family: "Open Sans", sans-serif;
      letter-spacing: -0.013rem;
      color: $primary_color;
      line-height: 1.38;
      
    }


    &:hover {
      cursor: pointer;
    }
  }

  &.case-study {
    max-width: 59.1rem;
    display: grid;
    grid-template-rows: auto auto 1fr;

    .card-text {
      padding: 0 1.1rem 0 3rem;
    }
  }

  .case-study-card-footer {
    @include flexBox(row, flex-end, space-between);

    padding: 0 1rem 1.2rem 3rem;

    .tags-container {
      @include flexBox(row, center, center);
      @include gap(0.4rem);
    }
  }
}

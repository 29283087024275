@import "styles/theme.scss";
@import "styles/config.scss";

.custom-button {
  border: none;
  outline: none;
  padding: 0.71rem 1.8rem;
  border-radius: 0.5rem;
  font-size: $text_size_1;
  border: 0.1rem solid $primary-color;
  cursor: pointer;

  @media (min-width: $breakpoint-mobile) {
    line-height: 1.31;
    font-size: $text_size_3;
    font-weight: 500;
  }

  a {
    color: inherit;
  }
}

.outlined {
  color: $primary-color;
  background: transparent;
  &:hover {
    color: $white-color;
    background: $primary-color;
  }
}

.filled {
  background: $primary-color;
  color: $white-color;
  &:hover {
    color: $primary-color;
    background: transparent;
  }
}

.main-case-studies {
  background: $alice_blue_color;

  .case-studies-section {
    padding: 5.5rem 7.3rem 11.6rem;

    @media (max-width: $breakpoint-mid-mobile) {
      padding: 5.5rem 7.3rem 5.5rem;
    }

    @media (max-width: $breakpoint-mobile) {
      padding: 5.5rem 1rem 5.5rem;
    }

    .case-studies-container {
      @include grid(repeat(2, 1fr));

      justify-content: center;
      align-items: stretch;
      gap: 3.8rem 5.3rem;
      margin-top: 5rem;

      @media (max-width: $breakpoint-mid-mobile) {
        @include grid(1fr);

        gap: 3rem;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

// component file imports
@import "./components/base/button.scss";

@import "./components/default-card.scss";
@import "./components/text-with-image-section.scss";
@import "./components/map-component.scss";

@import "./components/navbar.scss";
@import "./components/home-hero.scss";
@import "./components/case-studies-hero.scss";
@import "./components/about-us-hero.scss";
@import "./components/case-studies.scss";
@import "./components/stats.scss";
@import "./components/fan-experience.scss";
@import "./components/connect-section.scss";
@import "./components/testimonials-section.scss";
@import "./components/testimonials-section-connect.scss";
@import "./components/contact-section.scss";
@import "./components/footer.scss";
@import "./components/details-page-card.scss";
@import "./components/result-section.scss";
@import "./pages/details-page.scss";

@import "./forms/contact-form.scss";

// settup file imports
@import "./common-styles.scss";

//resets

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
}

html {
  font-size: 62.5%;

  ::selection {
    background-color: $primary_color;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 10px;
    border-right: 5px solid transparent;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    height: 20px;
    background: lightgray;
  }
}

body {
  margin: 0;
  background-color: $white_color;
  z-index: 0;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $secondary-color;
}

button {
  cursor: pointer;
  transition: all 0.2s linear;
}

figure {
  margin: 0;
}
.main-div {
  padding: 6%;
  margin-top: 8.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 31.9rem;
  overflow: hidden;
  .mainHeading {
    font-size: 3.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 1.48;
    margin: 0;
  }
  .subHeading {
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    line-height: 1.48;
    margin-top: 1.5rem;
    font-style: italic;
  }
  .articleHeading {
    font-size: 2.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    line-height: 1.48;
    margin-top: 1.5rem;
  }
  .articlePara {
    font-size: 1.5rem;
    font-family: "Open Sans", sans-serif;
    color: #13314a;
    line-height: 1.8;
    margin-top: 0.3rem;
  }
}

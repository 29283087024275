.details-page-card {
  position: absolute;
  top: 5.5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0.8rem;
  max-width: 122rem;
  background: $white_color;
  box-shadow: 0 0 6px 0 rgba($black_color, 0.16);
  padding-bottom: 2.8rem;

  @media (max-width: $breakpoint-large-laptops) {
    margin: 0 1rem;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 50.8rem;
    border-radius: 0.8rem;

    @media (max-width: $breakpoint-mobile) {
      height: 30rem;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 0.8rem;
    }

    .image-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      padding-bottom: 2rem;
      padding-top: 13rem;
      border-radius: 0.8rem;
      background-image: linear-gradient(
        to bottom,
        rgba($black_color, 0),
        $black_color
      );

      .details-title {
        margin-bottom: -0.5rem;
        font-size: $text_size_5;
        font-weight: normal;
      }

      .tags-container {
        @include flexBox(row, center, center);
        @include gap(0.4rem);
      }
    }
  }

  .stats-container {
    @include grid(repeat(3, auto));

    justify-content: center;
    align-items: center;
    gap: 15.5rem;

    @media (max-width: $breakpoint-mobile) {
      @include grid(auto);
    }

    .stats {
      @include grid(auto-fit, max-content);

      justify-content: center;
      align-items: center;
      text-align: center;
      height: 12.4rem;
      color: $black_color;

      .count {
        font-size: $text_size_8;
        font-family: 'Oswald', sans-serif;
        line-height: 1.48;
        margin: 0;
      }

      .title {
        font-size: $text_size_3;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.38;
        margin: 0;
      }
    }
  }

  .two-stats {
    grid-template-columns: repeat(2, auto);

    @media (max-width: $breakpoint-mobile) {
      @include grid(auto);
    }
  }

  .divider {
    display: block;
    margin: 2.1rem auto;
    width: 70vw;
    max-width: 59.4rem;
    height: 0.1rem;
    background-image: linear-gradient(
      to left,
      $white_color,
      #c0c0c0 51%,
      $white_color
    );
  }

  .description {
    max-width: 64.1rem;
    margin: auto;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: $text_size_3;
    color: rgba($secondary_color, 0.77);
    letter-spacing: -0.013rem;
    padding: 0 1rem;
  }
}

@import "../../styles/mixins.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: $white_color;
  transition: all 0.5s ease-in-out;
  box-shadow: rgb(0 0 0 / 10%) 0 0 2px 0;

  @media (min-width: $breakpoint-mobile-medium) {
    background-color: transparent;

    &.scroll-active {
      background-color: $white_color;
      box-shadow: rgb(0 0 0 / 10%) 0 0.1rem 0.5rem 0;

      .navbar {
        .nav-main-container {
          @media (min-width: $breakpoint-mobile-medium) {
            transition: all 0.5s ease-in-out;
            // padding: 1rem 0;
            padding: 1.5rem 0;
          }
        }
      }
    }
  }

  .navbar {
    .nav-main-container {
      @include grid(auto);

      gap: 1rem;
      place-items: center;
      padding: 2rem 0;

      .logo-container {
        margin: 0;
      }

      @media (min-width: $breakpoint-mobile) {
        @include grid(auto auto);

        gap: 2rem;
        background-color: transparent;
        // padding: 2.8rem 0 1.2rem;
        padding: 2rem 0;
        transition: all 0.5s ease-in-out;

        .logo-container {
          justify-self: start;
          cursor: pointer;
        }

        .nav-items {
          justify-self: end;
        }
      }

      .logo-with-hamburger {
        @include grid(auto auto);

        justify-content: space-between;
        align-items: center;
        width: 100%;

        .hamburger {
          border: none !important;
          outline: none !important;

          .line {
            display: block;
            width: 3rem;
            height: 0.3rem;

            position: relative;

            background: $secondary_color;
            border-radius: 0.3rem;

            z-index: 1;

            transform-origin: 0.4rem 0;

            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              opacity 0.55s ease;

            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }

            &.line1 {
              transform-origin: 0% 0%;
            }

            &.line2 {
              transform-origin: 0% 100%;
            }
          }

          &.--close {
            .line {
              opacity: 1;
              transform: rotate(45deg) translate(-0.3rem, -0.1rem);
              background: $secondary_color;
            }

            .line2 {
              transform: rotate(-45deg) translate(-0.8rem, 0.5rem);
            }

            .line3 {
              opacity: 0;
              transform: rotate(0deg) scale(0.2, 0.2);
            }
          }

          @media (min-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }

      .nav-items {
        // @include grid(auto auto auto auto);
        grid-template-columns: repeat(4, auto) !important;
        gap: 2rem;
        place-items: center;
        padding: 0;
        margin-bottom: 0;
        transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        @media (max-width: $breakpoint-mobile) {
          position: absolute;
          top: 6.7rem;
          left: 0;
          right: 0;
          padding: 1.4rem 0;
          background: $white_color;

          &.--open {
            border-top: 2px solid #f7f7f7;
            transform: scaleY(1);
            text-align: center;
          }

          &.--close {
            opacity: 0;
            top: 0;
            transform: scaleY(0);
          }
          .nav-item:not(:first-of-type) {
            margin-bottom: 1.1rem;
          }
        }

        @media (min-width: $breakpoint-mobile) {
          @include grid(auto auto auto);

          gap: 7.3rem;
        }

        @media (min-width: $breakpoint-mobile-max) {
          gap: 2.3rem;
        }

        .nav-item {
          > a {
            color: $secondary_color;
            font-weight: 500;
            font-size: $text_size_3;
            margin: 2rem;
            line-height: 3rem;
          }

          &.nav-active > a {
            color: $primary-color;
            line-height: 3rem;
            // padding: 2rem;
          }

          &.--button {
            margin-left: 0.4rem;
          }

          &:hover > a {
            transition: all 0.2s linear;
            color: $primary-color;
          }

          @media (min-width: $breakpoint-mobile-small) {
            > a {
              font-size: $text_size_4;
            }
          }
        }
      }
    }
  }
}

.main-container {
  margin: auto;
  max-width: 136.6rem;
  padding-left: 7.3rem;
  padding-right: 7.3rem;

  @media (max-width: $breakpoint-mobile-max) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.main-conatiner-padding {
  padding-left: 73px;
  padding-right: 73px;
}

.--blue {
  color: $primary_color_light;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.--white {
  color: $white_color;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.main-heading {
  font-size: $text_size_6;
  font-weight: bold;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: $white_color;

  @media (max-width: $breakpoint-mobile-small) {
    font-size: $text_size_5;
  }

  .--blue {
    color: $primary_color_light;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }

  &.--dark {
    color: $secondary_color;
  }
}

.tag {
  font-family: 'Open Sans', sans-serif;
  border-radius: 2px;
  font-size: $text_size_1;
  padding: 0.1rem 0.7rem;
  background-color: $light_blue_color;
  color: $primary_color;

  &.--inverse {
    background-color: $primary_color;
    color: $light_blue_color;
  }
}

.loading-screen {
  @include grid(1fr);

  place-items: center;
  position: fixed;
  inset: 0;
  z-index: 3;
  background: rgba($white_color, 0.9);
  margin: 0;
}

// styling for the elements out of dom

.ant-select-dropdown {
  &.ant-select-dropdown-placement-bottomLeft {
    background-color: $dark_blue_color;
    border: 0.1rem solid $fiord_color;
    box-shadow: 0 0 0.5rem rgba($white_color, 0.1);
    border-radius: 0.8rem;

    .ant-select-item {
      color: $white_color;
      min-height: 3.6rem;
      border-radius: 0.4rem;
    }

    .ant-select-item-option {
      align-items: center;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active {
      color: $white_color;
      font-weight: 600;
      background-color: rgba($white_color, 0.1);
    }
  }
}

.details-page-card-wrapper {
  position: relative;

  .testimonial-section {
    padding-bottom: 0 !important;
    min-height: auto;

    .carousel-card-container {
      @media (max-width: $breakpoint-mobile) {
        height: 35rem;
      }
      @media (max-width: 650px) {
        height: 40rem;
      }
      @media (max-width: 450px) {
        height: 45rem;
      }
      @media (max-width: 350px) {
        height: 50rem;
      }
      @media (max-width: 320px) {
        height: 55rem;
      }
    }

    &::before {
      background: none;
    }

    .monitor {
      margin-left: auto !important;
    }

    .--top-left {
      max-width: 42.6rem !important;
      top: 2.6rem !important;
      left: 7.6rem !important;
      min-height: 34.2rem !important;
    }

    .--center {
      right: 28.5rem;
    }
  }

  .details-page-connect {
    @include flexBox(column, center, center);

    background: $white_color;
    margin-top: 50.3rem;
    margin-bottom: 10rem;

    .problem-solution-container {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;

      @media (max-width: 810px) {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0px 1rem;
      }

      .p-s-heading {
        font-size: $text_size_6;
        font-weight: 700;
      }

      .p-s-description {
        font-size: $text_size_3;
        line-height: 2.5rem;
      }

      .p-s-list-section {
        margin-top: 2rem;
      }

      .p-s-list-heading {
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
        color: inherit;
      }

      .p-s-list {
        font-size: $text_size_3;
        margin-top: 0.2rem;
        margin-left: 1.5rem;

        .list-item {
          margin-bottom: 1.8rem;
        }

        .problem-list-item {
          list-style: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/white_dot.webp');
        }
        .solution-list-item {
          list-style: url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/primary_dot.webp');
        }
      }

      .problem-card {
        background: $primary_color_light;
        color: $white_color;
        padding: 9.9rem 5.5rem;
        z-index: 1;

        .problem-card-heading {
          color: $white_color;
        }
      }

      .solution-card {
        background: $cyan_blue_color;
        color: $secondary_color;
        padding: 7.1rem 5.4rem 7.1rem 12.3rem;
        margin-left: -7.5rem;

        @media (max-width: 810px) {
          padding-left: 5.4rem;
          margin-left: 0;
        }

        .solution-card-heading {
          color: $secondary_color;
        }
      }
    }

    .short-problem-container {
      grid-template-columns: 0.8fr 1.2fr;

      .solution-card {
        padding-top: 5.1rem;
        padding-bottom: 5.1rem;
      }

      @media (max-width: 810px) {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
      }
    }
  }

  .details-page-connect-small {
    @media (max-width: $breakpoint-mobile) {
      margin-top: 38.3rem;
    }
  }
}

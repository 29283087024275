.main-home-hero {
  position: relative;
  padding: 12.5rem 0 5rem;

  @media (max-width: $breakpoint-mobile) {
    padding: 7.5rem 0 5rem;
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background-image: linear-gradient(
      131deg,
      rgba(255, 255, 255, 0) 15%,
      rgba(56, 165, 254, 0.16) 89%
    );
    z-index: -2;
  }

  .hero-section {
    @include grid(1fr 61rem);

    align-items: center;
    justify-content: center;
    z-index: 0;
    min-height: 46.2rem;

    @media (max-width: $breakpoint-large-laptops) {
      @include grid(1fr 51rem);

      min-height: calc(46.2rem + 10rem);
    }

    @media (max-width: $breakpoint-desktop) {
      @include grid(1fr 41rem);
    }

    @media (max-width: $breakpoint-mid-mobile) {
      @include grid(auto);
      gap: 3rem;
    }

    .text-section {
      display: grid;
      grid-template-rows: 1fr 1.1fr auto;

      @media (min-width: 1125px) {
        grid-template-rows: 1fr 1fr auto;
      }
      @media (min-width: 800px) and (max-width: 1124px) {
        grid-template-rows: 1fr 0.9fr auto;
      }
      @media (min-width: 488px) and (max-width: 800px) {
        grid-template-rows: 1fr 1fr auto;
      }
      @media (min-width: 425px) and (max-width: 488px) {
        grid-template-rows: 1fr 0.8fr auto;
      }

      .custom-button {
        width: fit-content;
      }

      .hero-text {
        font-family: "Poppins", sans-serif;
        color: rgba($text_color_light, 0.77);
        font-size: $text_size_4;

        &.--main {
          font-size: $text_size_4;
          position: relative;
          padding-left: 1.5rem;

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: none;
            color: $black_color;
          }
        }
        .--lower {
          text-transform: lowercase;
        }

        @media (min-width: $breakpoint-mobile-medium) {
          font-size: $text_size_6;

          &.--main {
            font-size: $text_size_5_1;
          }
        }

        &.--bold {
          color: $text_color_light;

          .--blue {
            color: $primary_color_light;
            font-weight: bold;
            font-family: "Poppins", sans-serif;
          }
        }

        &.--animated {
          display: none;
        }

        &.active {
          display: block;
          animation: fade-in 0.5s linear forwards;
          overflow-y: hidden;
        }

        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(2rem);
          }
          to {
            opacity: 1;
          }
        }
      }
    }

    .images-section {
      position: relative;
      overflow: hidden;

      .fading-images-container {
        @include grid(61rem, 39.1rem);

        @media (max-width: $breakpoint-large-laptops) {
          @include grid(51rem, 39.1rem);
        }

        @media (max-width: $breakpoint-desktop) {
          @include grid(41rem, 39.1rem);
        }

        @media (max-width: $breakpoint-mid-mobile) {
          @include grid(auto);
        }

        & > img:first-of-type {
          position: absolute;
          clip: rect(0px, 510px, 310px, 0px);
        }
        & > img:nth-of-type(3) {
          position: absolute;
          clip: rect(0px, 510px, 360px, 0px);
        }

        .fading-image {
          grid-column: 1/2;
          grid-row: 1/2;
          width: 100%;
          max-width: 61rem;
          object-fit: contain;
          height: auto;
          min-width: 34rem;

          @media (min-width: $breakpoint-mobile-medium) {
            height: 39.1rem;
          }

          &.--animated {
            animation: fade-out-image 1s ease forwards;
          }

          &.active {
            animation: fade-in-image 1.5s ease forwards;
            overflow-y: hidden;
          }

          @keyframes fade-in-image {
            from {
              opacity: 0.1;
              transform: translateY(2rem);
            }
            to {
              opacity: 1;
            }
          }

          @keyframes fade-out-image {
            from {
              opacity: 0.7;
            }
            to {
              opacity: 0;
            }
          }
        }
      }

      .dots-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 65px;
        margin: 20px auto 0;
        justify-items: center;
      }

      .dots {
        background-color: #ccc;
        height: 15px;
        width: 15px;
        display: block;
        border-radius: 50%;

        &.active {
          background-color: #34a4ff;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .ellipse,
      .rectangle {
        position: absolute;
        z-index: -1;
        object-fit: contain;
      }

      .rectangle {
        display: grid;
        place-items: center;
        top: -2.7rem;
        right: 0;
        left: 0;

        img {
          width: 27.5rem;
          height: 46rem;
        }

        @media (max-width: $breakpoint-large-laptops) {
          top: 0;
          bottom: 0;

          img {
            height: 100%;
          }
        }

        @media (max-width: $breakpoint-desktop) {
          img {
            height: 33rem;
          }
        }

        @media (max-width: $breakpoint-mid-mobile) {
          top: -2rem;
          bottom: -2rem;

          img {
            position: absolute;
            height: 100%;
          }
        }
      }

      .ellipse {
        border-radius: 50%;

        &.--top-left {
          height: 1.4rem;
          width: 1.4rem;
          top: -2.5rem;
          left: 0.3rem;

          @media (max-width: $breakpoint-large-laptops) {
            top: 0;
          }

          @media (max-width: $breakpoint-desktop) {
            top: 3rem;
          }

          @media (max-width: $breakpoint-mid-mobile) {
            top: -2.5rem;
          }
        }

        &.--bottom-right {
          width: 9.5rem;
          height: 9.5rem;
          bottom: -3.5rem;
          right: -3.5rem;

          @media (max-width: $breakpoint-large-laptops) {
            bottom: 0;
          }

          @media (max-width: $breakpoint-desktop) {
            bottom: 3rem;
          }

          @media (max-width: $breakpoint-mid-mobile) {
            width: 3.5rem;
            height: 3.5rem;
            bottom: -1.5rem;
            right: 0;
          }
        }
      }
    }
  }
}

.main-fan-experience-section {
  background: $black_haze_color;

  .fan-experience-container {
    position: relative;
    padding: 6rem 7.3rem;

    @media (max-width: $breakpoint-mid-mobile) {
      padding: 6rem 1rem;
    }

    .cards-container {
      @include grid(repeat(2, 1fr));

      justify-content: center;
      align-items: stretch;
      gap: 4.8rem 5rem;
      margin-top: 3.3rem;

      @media (max-width: $breakpoint-mid-mobile) {
        @include grid(1fr);

        gap: 3rem;
      }
    }

    .sub-heading {
      font-family: "Poppins", sans-serif;
      color: $white_color;
      text-align: center;
      font-size: $text_size_5;
      line-height: 1.48;
      margin: 8rem 0 5rem;

      &.--dark {
        color: $secondary_color;
      }
    }

    .trusted-companies-container {
      @include grid(repeat(7, auto));

      gap: 5rem;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 6.6rem;
      right: 6.6rem;
      padding: 1.6rem;
      border-radius: 0.8rem;
      box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.16);
      background-color: $white_color;

      @media (max-width: $breakpoint-large-laptops) {
        @include grid(repeat(auto-fit, 14.9rem));

        position: static;
        min-width: unset;
      }

      .image-container {
        display: grid;
        place-items: center;
      }
    }
  }
}

.main-connect-section {
  background-color: rgba($mirage_color, 0.06);

  .connect-section {
    padding: 12.1rem 7.3rem 6.4rem;

    @media (max-width: $breakpoint-desktop) {
      padding: 12.1rem 1rem 6.4rem;
    }
    @media (max-width: $breakpoint-mobile-medium) {
      padding: 2rem 1rem 6.4rem;
    }
    .cards-container {
      @include grid(repeat(3, 1fr));

      gap: 2.4rem;
      place-items: baseline;
      justify-content: center;
      margin-top: 2.7rem;

      @media (max-width: $breakpoint-mid-mobile) {
        @include grid(auto);

        gap: 2.4rem 4rem;
      }
    }

    .custom-button {
      display: block;
      margin: 2.7rem auto 0;
    }
  }
}

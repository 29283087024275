.main-result-section {
  background: linear-gradient(
      to bottom,
      $secondary_color,
      rgba(19, 49, 74, 0.71)
    ),
    url('https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/result-stadium.webp') no-repeat center center;
  background-size: cover;

  .result-section {
    padding: 6.4rem 7rem;

    .results-container {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 1rem;
      margin-top: 2.7rem;
      justify-content: center;
    }

    .columns-3 {
      grid-template-columns: repeat(3, auto);

      @media (max-width: 650px) {
        grid-template-columns: repeat(1, auto);
      }
    }
    .columns-4 {
      grid-template-columns: repeat(4, auto);

      @media (max-width: 1240px) {
        grid-template-columns: repeat(2, auto);
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(1, auto);
      }
    }

    .result-card {
      display: grid;
      grid-template-rows: auto 1fr;
      background: $white_color;
      padding: 2rem;
      max-width: 30rem;
      min-height: 30rem;

      .result-card-header {
        display: flex;
        align-items: center;

        .card-number {
          color: $primary_color_light;
          font-family: 'Oswald', sans-serif;
          font-size: 36px;
          font-weight: 300;
          margin: 0;
          width: 100%;
        }

        .card-icon {
          width: 2.6rem;
          height: 2.6rem;
        }
      }

      .result-card-description {
        color: $secondary_color;
        font-size: $text_size_3;
        font-weight: 600;
        line-height: 1.56;

        .--blue {
          font-family: inherit;
        }
      }
    }

    .result-card-3 {
      max-width: 40rem;
      min-height: 30rem;
    }
  }
}

.main-footer-section {
  background-color: $secondary_color;

  @media (min-width: $breakpoint-tablet) {
    background: url("https://storage.googleapis.com/fk-gp/websiteAssets/fankave/footer/footer-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .footer {
    text-align: center;
    padding: 6.8rem 0 1.6rem;

    .content-wrapper {
      .footer-links {
        @include flexBox(row, center, center);
        @include gap(2rem);

        margin-top: 3rem;
        font-size: $text_size_3;

        @media (max-width: $breakpoint-mobile-medium) {
          @include gap(1rem);
        }
        // .footer-links:not(:first-child) {
        .link:not(:first-of-type) {
          border-left: 0.1rem solid rgba($white_color, 0.2);
        }
        // }
        .link {
          padding-left: 1rem;
          a {
            color: rgba($white_color, 0.7);
          }
          // &:not(:first-child) {
          //   &::after {
          //     content: "";
          //     border-left: 0.1rem solid rgba($white_color, 0.2);
          //     margin-right: 2rem;

          //     @media (max-width: $breakpoint-mobile-medium) {
          //       margin-right: 1rem;
          //     }
          //   }
          // }
          // &:not(:first-child) {
          //   &::before {
          //     content: "";
          //     border-left: 0.1rem solid rgba($white_color, 0.2);
          //     margin-right: 2rem;

          //     @media (max-width: $breakpoint-mobile-medium) {
          //       margin-right: 1rem;
          //     }
          //   }
          // }
        }
      }

      .copyright {
        color: $white_color;
        font-size: $text_size_3;
        margin-top: 5rem;
      }
    }
  }
}
